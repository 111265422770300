.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
.frontend-butler-oauth-success__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
}
.frontend-butler-oauth-success__wrapper .frontend-butler-branded-form {
  max-width: 478px;
}
.frontend-butler-oauth-success__access {
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
              -ms-grid-row-align: stretch;
          align-self: stretch;
  padding: 64px 0 0;
}
.frontend-butler-oauth-success__access .frontend-butler-branded-form__form {
  margin-top: 0;
}
.frontend-butler-oauth-success__org-logo {
  width: 64px;
  height: 64px;
  margin: auto;
  margin-bottom: 28px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-oauth-success__org-logo {
    margin-bottom: 16px;
  }
}
.frontend-butler-oauth-success__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  alighn-items: center;
}
.frontend-butler-oauth-success__desc {
  margin-bottom: 28px;
  text-align: center;
  color: #131f29;
  font-size: 20px;
  line-height: 28px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-oauth-success__desc {
    margin-bottom: 16px;
  }
}
.frontend-butler-oauth-success__form-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  cursor: pointer;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-oauth-success__form-close {
    display: block;
  }
}
.frontend-butler-oauth-success__form-close-icon {
  width: 26px;
  height: 26px;
  color: #93a1af;
}
