.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-header {
    height: 64px;
  }
}
.frontend-butler-header--short {
  margin-top: 20px;
  margin-bottom: 0;
}
.frontend-butler-header--short .frontend-butler-header__logo {
  display: block;
  width: 112px;
  margin: auto;
}
.frontend-butler-header--full {
  height: 72px;
  padding: 0 20px;
  background-color: #fff;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-header--full .frontend-butler-header__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  max-width: 1600px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-header--full .frontend-butler-header__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.frontend-butler-header--full .frontend-butler-header__logo-img {
  display: block;
  width: 155px;
  height: 31.09px;
  margin: auto;
}
.frontend-butler-header--full .frontend-butler-header__sign-up-row:not(:last-child) {
  margin-bottom: 10px;
}
.frontend-butler-header--full .frontend-butler-header__sign-up-body {
  width: 320px;
  margin: 20px;
  padding: 0;
  list-style: none;
  font-size: 18px;
}
.frontend-butler-header--full .frontend-butler-header__sign-up-btn {
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #476085;
  font-size: 16px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-header--full .frontend-butler-header__controls {
  display: inherit;
}
@media (max-width: 340px) {
  .frontend-butler-header--full .frontend-butler-header__controls .frontend-butler-button--l {
    padding: 0 24px;
  }
}
