.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
.frontend-butler-registration {
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration .frontend-butler-header__content {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.frontend-butler-registration .frontend-butler-init-registration {
  padding-bottom: 70px;
}
.frontend-butler-registration .frontend-butler-init-registration .frontend-butler-registration-form__error-banner {
  margin-top: 0;
}
.frontend-butler-registration .frontend-butler-init-registration.frontend-butler-container-with-header {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 34px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration .frontend-butler-init-registration.frontend-butler-container-with-header {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration .frontend-butler-init-registration .frontend-butler-container-with-header__header {
    display: none;
  }
}
.frontend-butler-registration .frontend-butler-init-registration .frontend-butler-container-with-header__content {
  margin: 0;
  padding: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration .frontend-butler-init-registration .frontend-butler-container-with-header__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}
.frontend-butler-registration .frontend-butler-init-registration__form {
  width: 350px;
  margin: 38px auto 0;
  padding-bottom: 72px;
}
@media (max-width: 400px) {
  .frontend-butler-registration .frontend-butler-init-registration__form {
    width: auto;
    margin: 24px 12px 0;
    padding-bottom: 48px;
  }
}
.frontend-butler-registration .frontend-butler-init-registration__form-content .frontend-butler-grecaptcha {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: scale( 1.1513157894736843 );
      -ms-transform: scale( 1.1513157894736843 );
          transform: scale( 1.1513157894736843 );
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.frontend-butler-registration .frontend-butler-init-registration__btn-confirm-icon {
  position: relative;
  top: -1px;
  height: 17px;
  margin-left: 11px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.frontend-butler-registration .frontend-butler-init-registration__success {
  width: 400px;
  margin: auto;
  text-align: center;
  color: #3faaa8;
  font-size: 16px;
}
@media (max-width: 768px) {
  .frontend-butler-registration .frontend-butler-init-registration__success {
    width: auto;
    max-width: 400px;
  }
}
.frontend-butler-registration .frontend-butler-init-registration__complete-message {
  text-align: center;
  color: #3faaa8;
  font-size: 16px;
}
