.frontend-butler-bg-slider {
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  background: #fff;
}
.frontend-butler-bg-slider__logo {
  display: block;
  width: 158px;
}
.frontend-butler-bg-slider__logo--main {
  position: fixed;
  z-index: 5;
  top: 18px;
  left: 58px;
}
.frontend-butler-bg-slider__logo-img {
  display: block;
  width: 100%;
}
.frontend-butler-bg-slider__slides {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 900px) {
  .frontend-butler-bg-slider__slides {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .frontend-butler-bg-slider__slides {
    right: 640px;
    width: auto;
  }
}
.frontend-butler-bg-slider__slides::before {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5265);
}
.frontend-butler-bg-slider__slides-content {
  text-align: center;
  color: #fff;
  font-size: 40px;
  line-height: 56px;
}
.frontend-butler-bg-slider__slides-content-wrapper {
  position: relative;
  z-index: 10;
  display: none;
  width: 60%;
  max-width: 400px;
}
@media (min-width: 900px) {
  .frontend-butler-bg-slider__slides-content-wrapper {
    display: block;
  }
}
.frontend-butler-bg-slider__slides-content + .frontend-butler-bg-slider__slides-content-description {
  position: relative;
  margin-top: 66px;
}
.frontend-butler-bg-slider__slides-content + .frontend-butler-bg-slider__slides-content-description::before {
  position: absolute;
  top: -33px;
  left: 38%;
  width: 24%;
  height: 0;
  content: '';
  border-top: 2px solid #fbbb30;
}
.frontend-butler-bg-slider__slides-content-description {
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
}
.frontend-butler-bg-slider__content {
  position: relative;
  z-index: 10;
  top: 0;
  left: 50%;
  display: block;
  width: 100%;
  min-width: 320px;
  max-width: 450px;
  min-height: 100vh;
  padding: 0 0 18px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
}
@media (min-width: 900px) {
  .frontend-butler-bg-slider__content {
    left: 50%;
    width: 50%;
    max-width: 640px;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
  }
}
@media (min-width: 1200px) {
  .frontend-butler-bg-slider__content {
    left: calc(100% - 640px);
  }
}
.frontend-butler-bg-slider__content-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  padding: 18px 18px 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.frontend-butler-bg-slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity ease-in-out 0.6s;
  -o-transition: opacity ease-in-out 0.6s;
  transition: opacity ease-in-out 0.6s;
  opacity: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.frontend-butler-bg-slider__slide--active {
  opacity: 1;
}
