.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
.frontend-butler-init-registration__form-content-header {
  text-align: center;
  color: #476085;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-init-registration__form-content .frontend-butler-grecaptcha {
  margin-top: 15px;
  margin-bottom: 50px;
}
.frontend-butler-init-registration__form-confirm {
  margin-top: 40px;
}
.frontend-butler-init-registration__success {
  padding: 75px 28px;
}
.frontend-butler-init-registration__success-wrapper {
  width: 600px;
  margin: 34px auto;
  border-radius: 3px;
  background: #fff;
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
@media (max-width: 768px) {
  .frontend-butler-init-registration__success-wrapper {
    width: auto;
    margin: 24px 12px;
  }
}
.frontend-butler-init-registration__success-img {
  position: relative;
  left: -35px;
}
.frontend-butler-init-registration__terms-label {
  color: #476085;
  line-height: 20px;
}
.frontend-butler-init-registration__terms-label-link.frontend-butler-init-registration__terms-label-link {
  color: #158dd2;
  line-height: 20px;
}
.frontend-butler-init-registration__business {
  padding: 90px 150px 72px;
}
.frontend-butler-init-registration__business-p {
  display: block;
  margin-bottom: 32px;
  color: #131f29;
  font-size: 16px;
  line-height: 26px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-init-registration__business-submit {
  margin-top: 200px;
}
.frontend-butler-init-registration__checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.frontend-butler-init-registration__back {
  margin-top: 30px;
}
.frontend-butler-input-row--required .frontend-butler-init-registration__checkbox .frontend-butler-init-registration__terms-label::after {
  position: absolute;
  z-index: 3;
  margin-left: 3px;
  content: '*';
  font-size: 1.2em;
  line-height: inherit;
}
.frontend-butler-init-registration__divider {
  text-align: center;
  color: #adb5be;
  font-size: 12px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 18px;
}
.frontend-butler-init-registration__social-login {
  width: 350px;
  margin: 18px auto 0;
}
.frontend-butler-init-registration__social-login + .frontend-butler-init-registration__form {
  margin-top: 18px;
}
.frontend-butler-init-registration__login-btn:not(:first-child) {
  margin-top: 12px;
}
.frontend-butler-init-registration__google-icon,
.frontend-butler-init-registration__facebook-icon {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
