.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration-form .frontend-butler-input-row__label {
    font-size: 14px;
  }
}
.frontend-butler-registration-form__terms-label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 269px;
      -ms-flex: 0 0 269px;
          flex: 0 0 269px;
  color: #476085;
  line-height: 20px;
}
.frontend-butler-registration-form__terms-label-link.frontend-butler-registration-form__terms-label-link {
  color: #158dd2;
  line-height: 20px;
}
.frontend-butler-registration-form__captcha {
  margin-top: 15px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration-form__captcha {
    margin-top: 20px;
  }
}
.frontend-butler-registration-form__row--buttons {
  margin-top: 52px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-registration-form__row--buttons {
    margin-top: 20px;
  }
}
.frontend-butler-registration-form__checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.frontend-butler-input-row--required .frontend-butler-registration-form__checkbox .frontend-butler-registration-form__terms-label::after {
  margin-left: 3px;
  content: '*';
  font-size: 1.2em;
  line-height: inherit;
}
.frontend-butler-registration-form__consent .frontend-butler-input-row__control {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
}
.frontend-butler-registration-form__error-banner {
  margin-top: 20px;
}
.frontend-butler-registration-form__error-banner .frontend-butler-info__message:not(:last-child) {
  margin-bottom: 8px;
}
.frontend-butler-registration-form .frontend-butler-password-input__hint {
  display: block;
  width: 100%;
}
