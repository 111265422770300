.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
.frontend-butler-password__form.frontend-butler-container-with-header {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 34px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-password__form.frontend-butler-container-with-header {
    margin-top: 10px;
  }
}
.frontend-butler-password__form.frontend-butler-container-with-header .frontend-butler-container-with-header__header {
  text-transform: capitalize;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-password__form.frontend-butler-container-with-header .frontend-butler-container-with-header__header {
    display: none;
  }
}
.frontend-butler-password__form.frontend-butler-container-with-header .frontend-butler-container-with-header__content {
  padding: 36px;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-password__form.frontend-butler-container-with-header .frontend-butler-container-with-header__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}
.frontend-butler-password__info {
  width: 100%;
  margin-bottom: 36px;
}
.frontend-butler-password__form-content {
  width: 100%;
}
.frontend-butler-password__form-content .frontend-butler-input-row__label {
  margin-right: 8px;
  padding-top: 8px;
  color: #476085;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
@media only screen and (max-width: 600px) {
  .frontend-butler-password__form-content .frontend-butler-input-row__label {
    font-size: 14px;
  }
}
.frontend-butler-password__row--controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.frontend-butler-password__info {
  color: #adb5be;
  font-size: 16px;
  line-height: 24px;
}
