.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
.frontend-butler-mixin-wrapper-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.frontend-butler-mixin-content-width {
  width: 100%;
  max-width: 1600px;
}
.frontend-butler-mixin-dropshadow-light {
  -moz-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
          box-shadow: 0 5px 10px 0 rgba(47, 60, 85, 0.1);
}
.frontend-butler-mixin-dropshadow {
  -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
          box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
}
.frontend-butler-font {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-font--semi-bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.frontend-butler-font--bold {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.frontend-butler-font--light {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.frontend-butler-font--light-it {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
.frontend-butler-branded-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  border: 0;
  border-radius: 4px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media only screen and (min-width: 480px) {
  .frontend-butler-branded-form {
    border: 1px solid #f2f4f7;
    -moz-box-shadow: 0 2px 10px 0 rgba(47, 60, 85, 0.2);
    -webkit-box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
            box-shadow: 0 2px 10px 0 rgba(76, 96, 125, 0.2);
  }
}
.frontend-butler-branded-form__header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  padding: 32px;
  background: #f2f4f7;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.frontend-butler-branded-form__header::after {
  position: absolute;
  bottom: -18px;
  left: 50%;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 28px;
  height: 18px;
  content: '';
  -webkit-transform: translateX(-14px);
      -ms-transform: translateX(-14px);
          transform: translateX(-14px);
  border-width: 18px 14px 0;
  border-style: solid;
  border-color: #f2f4f7 transparent transparent transparent;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__header::after {
    display: none;
  }
}
.frontend-butler-branded-form__header-logo {
  display: block;
  overflow: hidden;
  height: 64px;
  margin-right: 16px;
  background: transparent center no-repeat;
  -webkit-background-size: contain;
          background-size: contain;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 64px;
      -ms-flex: 0 0 64px;
          flex: 0 0 64px;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__header-logo {
    height: 48px;
    margin-right: 12px;
    -webkit-flex-basis: 48px;
        -ms-flex-preferred-size: 48px;
            flex-basis: 48px;
  }
}
.frontend-butler-branded-form__header-descriptipn {
  text-align: left;
  color: #131f29;
  font-size: 16px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__header-descriptipn {
    font-size: 12px;
  }
}
.frontend-butler-branded-form__header-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  cursor: pointer;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__header-close {
    display: block;
  }
}
.frontend-butler-branded-form__header-close-icon {
  width: 26px;
  height: 26px;
  color: #93a1af;
}
.frontend-butler-branded-form__footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 60px 0 28px;
  text-align: center;
  text-decoration: none;
  color: #adb5be;
  font-size: 12px;
  line-height: 22px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.frontend-butler-branded-form__footer-logo {
  width: 150px;
  height: 22px;
  margin-left: .6em;
  fill: currentColor;
}
.frontend-butler-branded-form__form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin: auto;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__form {
    max-width: 100%;
    padding: 0 32px;
  }
}
.frontend-butler-branded-form__form-header {
  margin: 0;
  padding: 48px 0;
  text-align: center;
  color: #131f29;
  font-size: 20px;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@media only screen and (max-width: 476px) {
  .frontend-butler-branded-form__form-header {
    padding: 32px 0;
    font-size: 24px;
  }
}
.frontend-butler-branded-form__row--link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 12px;
  text-align: center;
  color: #adb5be;
  font-size: 12px;
}
.frontend-butler-branded-form__row--link .link {
  margin-top: 12px;
  text-transform: uppercase;
  font-size: 16px;
}
.frontend-butler-branded-form__checkbox-row {
  margin-bottom: 80px;
}
.frontend-butler-branded-form__social-login {
  width: 300px;
  margin: 12px 0 0;
}
.frontend-butler-branded-form__login-btn:not(:first-child) {
  margin-top: 12px;
}
.frontend-butler-branded-form__google-icon,
.frontend-butler-branded-form__facebook-icon {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
